/* audiowide-400normal - latin */
@font-face {
  font-family: 'Audiowide';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src:
    local('Audiowide Regular '),
    local('Audiowide-Regular'),
    url('./files/audiowide-latin-400.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/audiowide-latin-400.woff') format('woff'); /* Modern Browsers */
}

